import type { ParentComponent } from "solid-js";
import type { ProgramWrapper } from "~/utils/program_wrapper";

import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";
import { RealEstateContext } from "~/types/common";

type RequestContextStore = [
  {
    isFrontPage: boolean;
    hasGmapsLoaded: boolean;
    hasGlideRequested: boolean;
    hasGlideLoaded: boolean;
    hasMapboxLoaded: boolean;
    hasMapboxRequested: boolean;
    realEstateContext: RealEstateContext;
    wrapper?: ProgramWrapper;
  },
  {
    setIsFrontPage?: (isFront: boolean) => void;
    setRealEstateContext?: (context: RealEstateContext) => void;
    setGlideRequested?: (hasLoaded: boolean) => void;
    setHasGlideLoaded?: (hasLoaded: boolean) => void;
    setHasGmapsLoaded?: (hasLoaded: boolean) => void;
    setHasMapboxLoaded?: (hasLoaded: boolean) => void;
    setHasMapboxRequested?: (hasLoaded: boolean) => void;
    setWrapper?: (wrapper: ProgramWrapper) => void;
  },
];

const RequestContext = createContext<RequestContextStore>([
  {
    isFrontPage: false,
    realEstateContext: RealEstateContext.Unknown,
    hasGmapsLoaded: false,
    hasGlideRequested: false,
    hasGlideLoaded: false,
    hasMapboxLoaded: false,
    hasMapboxRequested: false,
  },
  {},
]);

export const RequestContextProvider: ParentComponent<{
  isFrontPage?: boolean;
  realEstateContext?: RealEstateContext;
  hasGmapsLoaded?: boolean;
  hasGlideRequested?: boolean;
  hasGlideLoaded?: boolean;
  hasMapboxLoaded?: boolean;
  wrapper?: ProgramWrapper;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      isFrontPage: props.isFrontPage || false,
      // eslint-disable-next-line solid/reactivity
      realEstateContext: props.realEstateContext || RealEstateContext.Unknown,
      hasGmapsLoaded: false,
      hasGlideRequested: false,
      hasGlideLoaded: false,
      hasMapboxLoaded: false,
      hasMapboxRequested: false,
      // eslint-disable-next-line solid/reactivity
      wrapper: props.wrapper,
    }),
    store: RequestContextStore = [
      state,
      {
        setIsFrontPage(isFront: boolean) {
          setState("isFrontPage", isFront);
        },
        setRealEstateContext(context: RealEstateContext) {
          setState("realEstateContext", context);
        },
        setHasGmapsLoaded(loaded: boolean) {
          setState("hasGmapsLoaded", loaded);
        },
        setGlideRequested(loaded: boolean) {
          setState("hasGlideRequested", loaded);
        },
        setHasGlideLoaded(loaded: boolean) {
          setState("hasGlideLoaded", loaded);
        },
        setHasMapboxLoaded(loaded: boolean) {
          setState("hasMapboxLoaded", loaded);
        },
        setHasMapboxRequested(loaded: boolean) {
          setState("hasMapboxRequested", loaded);
        },
        setWrapper(wrapper: ProgramWrapper) {
          setState("wrapper", wrapper);
        },
      },
    ];

  return (
    <RequestContext.Provider value={store}>
      {props.children}
    </RequestContext.Provider>
  );
};

export function useRequestContext() {
  return useContext(RequestContext);
}
